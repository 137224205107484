import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSidebarContext } from './useSidebar';

export interface NavLinkGroupProps {
    Icon: typeof ChevronDownIcon;
    label: string;
    isActive: boolean;
    children: Array<{ to: string; label: string }>;
}

const NavLinkGroup = ({ Icon, label, isActive, children }: NavLinkGroupProps) => {
    const [open, setOpen] = useState<boolean>(isActive);
    const { setSidebarExpanded, sidebarExpanded } = useSidebarContext();

    const handleClick = () => setOpen(!open);

    useEffect(() => {
        if (!isActive) {
            setOpen(false);
        }
    }, [isActive, setOpen]);

    const ChevronIcon = open ? ChevronUpIcon : ChevronDownIcon;

    return (
        <li>
            <NavLink
                className={clsx(
                    'text-bodydark1 hover:bg-graydark group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium duration-300 ease-in-out',
                    isActive ? 'bg-graydark' : null
                )}
                onClick={e => {
                    e.preventDefault();
                    sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
                to="#"
            >
                <Icon className="h-5 w-5 fill-none" />
                {label}
                <ChevronIcon className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 fill-current" />
            </NavLink>

            <div className={clsx('translate transform overflow-hidden', !open ? 'hidden' : '')}>
                <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                    {children.map(({ to, label }) => (
                        <li key={to}>
                            <NavLink
                                className={({ isActive }) =>
                                    clsx(
                                        'text-bodydark2 group relative flex items-center gap-2.5 rounded-md px-4 font-medium duration-300 ease-in-out hover:text-white',
                                        isActive ? '!text-white' : null
                                    )
                                }
                                to={to}
                            >
                                {label}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
};

export default NavLinkGroup;
