/// <reference types="vite-plugin-svgr/client" />
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import Logo from './logo-icon.svg?react';

interface HeaderProps {
    sidebarOpen: string | boolean | undefined;
    setSidebarOpen: (arg0: boolean) => void;
}

const Header = ({ sidebarOpen, setSidebarOpen }: HeaderProps) => (
    <header className="drop-shadow-1 sticky top-0 z-999 flex w-full bg-white">
        <div className="shadow-2 flex flex-grow items-center justify-between px-4 py-4 md:px-6 2xl:px-11">
            <div className="relative flex w-full items-center gap-2 sm:gap-4 lg:hidden">
                <button
                    aria-controls="sidebar"
                    className="border-stroke z-99999 block rounded-sm border bg-white p-1.5 shadow-sm lg:hidden"
                    onClick={e => {
                        e.stopPropagation();
                        setSidebarOpen(!sidebarOpen);
                    }}
                    type="button"
                >
                    <Bars3Icon className="h-5 w-5" />
                </button>

                <div className="absolute block w-full p-1.5 lg:hidden">
                    <Link to="/">
                        <Logo className="h-8 w-full rounded-sm" fill="black" />
                    </Link>
                </div>
            </div>

            <div className="hidden sm:block">
                <form action="https://formbold.com/s/unique_form_id" method="POST">
                    <div className="relative">
                        <button className="absolute left-0 top-1/2 -translate-y-1/2" type="button">
                            <MagnifyingGlassIcon className="h-6 w-6" />
                        </button>
                        <input
                            className="w-full bg-transparent pl-9 pr-4 focus:outline-none"
                            placeholder="Type to search..."
                            type="text"
                        />
                    </div>
                </form>
            </div>
        </div>
    </header>
);

export default Header;
