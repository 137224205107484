import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import React, { useState, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { handleError } from '../../common/Error';
import Header from './Header';
import Loader from './Loader';
import Sidebar from './Siderbar';

const beforeCapture: ErrorBoundaryProps['beforeCapture'] = scope => {
    scope.setTag('boundary', 'DashboardLayout');
};

const DashboardLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="flex h-screen animate-fade overflow-hidden animate-once animate-ease-out">
            <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                <Header setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
                <main className="h-full">
                    <div className="mx-auto h-full max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                        <ErrorBoundary beforeCapture={beforeCapture} fallback={handleError}>
                            <Suspense fallback={<Loader />}>
                                <Outlet />
                            </Suspense>
                        </ErrorBoundary>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DashboardLayout;
