import clsx from 'clsx';
import React, { ForwardRefExoticComponent, PropsWithoutRef, SVGProps, RefAttributes } from 'react';
import { NavLink } from 'react-router-dom';

export interface NavLinkItemProps {
    Icon: ForwardRefExoticComponent<
        PropsWithoutRef<SVGProps<SVGSVGElement>> & {
            title?: string;
            titleId?: string;
        } & RefAttributes<SVGSVGElement>
    >;
    label: string;
    to: string;
    isActive: boolean;
}

const NavLinkItem = ({ Icon, label, to, isActive }: NavLinkItemProps) => (
    <li>
        <NavLink
            className={clsx(
                'text-bodydark1 hover:bg-graydark group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium duration-300 ease-in-out',
                isActive ? 'bg-graydark' : null
            )}
            to={to}
        >
            <Icon className="h-5 w-5 fill-none" />
            {label}
        </NavLink>
    </li>
);

export default NavLinkItem;
