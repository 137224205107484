/// <reference types="vite-plugin-svgr/client" />
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
    Squares2X2Icon,
    EnvelopeIcon,
    Cog6ToothIcon,
    KeyIcon,
    ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import NavLinkGroup from './NavLinkGroup';
import NavLinkItem from './NavLinkItem';
import Logo from './logo.svg?react';
import useSidebar, { SidebarProvider } from './useSidebar';

interface SidebarProps {
    sidebarOpen: boolean;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
    const { t } = useTranslation('sidebar');
    const location = useLocation();
    const { pathname } = location;

    const context = useSidebar(sidebarOpen, setSidebarOpen);

    return (
        <SidebarProvider value={context}>
            <aside
                ref={context.sidebar}
                className={clsx(
                    'absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300 ease-linear lg:static lg:translate-x-0',
                    sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                )}
            >
                <div className="flex items-center justify-between gap-2 px-6 py-2">
                    <NavLink to="/">
                        <Logo className="w-full" />
                    </NavLink>

                    <button
                        ref={context.trigger}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                        className="block lg:hidden"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        type="button"
                    >
                        <XMarkIcon className="h-7 w-7" />
                    </button>
                </div>

                <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                    <nav className="px-4 py-4 lg:px-6">
                        <div>
                            <h3 className="text-bodydark2 mb-4 ml-4 text-sm font-semibold">
                                {t('sidebar:featuresGroup.title')}
                            </h3>

                            <ul className="mb-6 flex flex-col gap-1.5">
                                <NavLinkItem
                                    Icon={Squares2X2Icon}
                                    isActive={pathname === '/'}
                                    label={t('sidebar:featuresGroup.items.dashboard')}
                                    to="/"
                                />

                                <NavLinkItem
                                    Icon={EnvelopeIcon}
                                    isActive={pathname.startsWith('/inbox')}
                                    label={t('sidebar:featuresGroup.items.inbox')}
                                    to="/inbox"
                                />

                                <NavLinkGroup
                                    Icon={Cog6ToothIcon}
                                    isActive={location.pathname.startsWith('/demo')}
                                    label="Demo"
                                >
                                    {[
                                        { to: '/demo/404', label: t('sidebar:featuresGroup.items.404') },
                                        { to: '/demo/500', label: t('sidebar:featuresGroup.items.500') },
                                    ]}
                                </NavLinkGroup>
                            </ul>
                        </div>

                        <div>
                            <h3 className="text-bodydark2 mb-4 ml-4 text-sm font-semibold">
                                {t('sidebar:accountGroup.title')}
                            </h3>

                            <ul className="mb-6 flex flex-col gap-1.5">
                                <NavLinkItem
                                    Icon={KeyIcon}
                                    isActive={pathname.startsWith('/profile/authentication')}
                                    label={t('sidebar:accountGroup.items.authentication')}
                                    to="/profile/authentication"
                                />
                                <NavLinkItem
                                    Icon={ArrowRightOnRectangleIcon}
                                    isActive={pathname.startsWith('/profile/signout')}
                                    label={t('sidebar:accountGroup.items.signout')}
                                    to="/profile/signout"
                                />
                            </ul>
                        </div>
                    </nav>
                </div>
            </aside>
        </SidebarProvider>
    );
};

export default Sidebar;
