import { Dispatch, SetStateAction, useEffect, useRef, useState, createContext, useContext } from 'react';

const useSidebar = (sidebarOpen: boolean, setSidebarOpen: Dispatch<SetStateAction<boolean>>) => {
    const trigger = useRef<any>(null);
    const sidebar = useRef<any>(null);

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    );

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: MouseEvent) => {
            if (!sidebar.current || !trigger.current) {
                return;
            }

            if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) {
                return;
            }

            setSidebarOpen(false);
        };

        document.addEventListener('click', clickHandler);

        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: KeyboardEvent) => {
            if (!sidebarOpen || keyCode !== 27) {
                return;
            }

            setSidebarOpen(false);
        };

        document.addEventListener('keydown', keyHandler);

        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());

        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document.querySelector('body')?.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    return { trigger, sidebar, sidebarExpanded, setSidebarExpanded };
};

export type SidebarContextValue = ReturnType<typeof useSidebar>;

const Context = createContext<SidebarContextValue | null>(null);

export const SidebarProvider = Context.Provider;

export const useSidebarContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useSidebarContext must be used within SidebarProvider');
    }

    return context;
};

export default useSidebar;
